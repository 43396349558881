/* styles.css */

.tamnuniButton {
  position: relative;
  overflow: hidden; /* Ensures the pseudo-element doesn't overflow */
  background-color: white; /* Starting background color */
  /* Add your existing button styles here */
}

.tamnuniButton::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.tamnuniButton-animate::before {
  animation: progress 2s ease-in-out forwards;
  background-color: rgba(0, 163, 148, 0.12); /* This is the color that will fill the button */
}

.tamnuniButton-animate-primary {
  z-index: 1;
}

.tamnuniButton-animate-primary::before {
  animation: progress 2s ease-in-out forwards;
  background-color: rgba(255, 255, 255, 0.3); /* This is the color that will fill the button */
  z-index: 0; /* Keep it behind the button content */
}

@keyframes progress {
  0% {
    left: -100%;
    background-color: transparent;
  }
  10% {
    left: -97%;
  }
  20% {
    left: -92%;
  }
  30% {
    left: -82%;
  }
  30% {
    left: -62%;
  }
  40% {
    left: -38%;
  }
  50% {
    left: -18%;
  }
  60% {
    left: -14%;
  }
  80% {
    left: -7%;
  }
  90% {
    left: -3%;
  }
  100% {
    left: 0%;
  }
}

@font-face {
  font-family: 'Gerstner-Programm';
  src:
    local('Gerstner-ProgrammMedium'),
    url('./static/fonts/Gerstner-ProgrammMedium.woff2') format('woff2');
}

.App h1 {
  font-family: 'Gerstner-Programm', sans-serif;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.75);
  font-size: 30px;
}
.App h2 {
  font-family: 'Gerstner-Programm', sans-serif;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.75);
  font-size: 22px;
}
.App h3 {
  font-family: 'Gerstner-Programm', sans-serif;
}
.App h4 {
  font-family: 'Gerstner-Programm', sans-serif;
}

body {
  background-color: transparent !important;
}

.App {
  background-color: #ffffff;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App a {
  text-decoration: none;
  color: #00a394;
}

.App a > svg {
  vertical-align: text-bottom;
}

.App header .MuiToolbar-root .MuiIconButton-root {
  color: #fff;
}

.App .MuiList-root .MuiListItemIcon-root {
  color: #00a394;
}

.App .MuiList-root .MuiListItemText-root {
  color: rgba(0, 0, 0, 0.87);
}

.App .MuiAutocomplete-root input:placeholder-shown {
  font-style: italic;
}

.searchbox-outer {
  border-radius: 30px;
  border: 1px solid rgba(0, 0, 0, 0.32);
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 2px;
  padding-bottom: 2px;
  height: 60px;
  line-height: 54px;
  overflow-y: auto;
}

.searchbox .MuiOutlinedInput-root {
  border-radius: 30px !important;
  /* box-shadow: 8px 11px 18px -10px rgba(0,0,0,0.32); */
}

.searchbox .MuiInputLabel-shrink {
  display: none;
}

.searchbox .MuiInput-underline::after {
  border: 0 !important;
}

.searchbox .MuiInput-underline::before {
  border: 0 !important;
}

.searchbox label + .MuiInput-formControl {
  margin-top: 0;
}

/*
.MuiInputBase-input, .MuiInput-input {
    padding: 0 !important;
}
*/

.searchbox .MuiInputLabel-formControl {
  transform: translate(0, 12px) scale(1);
}

.searchbox .MuiFormLabel-root.Mui-focused {
  color: rgba(0, 0, 0, 0.54);
}

.searchbox .MuiInputBase-multiline {
}

.searchbox .MuiAutocomplete-inputRoot[class*='MuiInput-root'] {
  padding-bottom: 7px;
}

.searchbox .MuiTextField-root {
}

.App .MuiListItemIcon-root {
  min-width: 28px;
}

.App .intro-status-list .MuiListItemIcon-root {
  min-width: 50px;
}

.App .MuiListItemIcon-root.contact-checkbox {
  min-width: 0 !important;
  margin-right: -10px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App .MuiDivider-root {
  height: 2px;
}
.App li > .MuiTypography-caption {
  font-size: 0.85rem;
}

.searchbox textarea {
}

.searchbox .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #cdd5d4;
}

.searchbox .MuiAutocomplete-paper {
  border-bottom-right-radius: 20px !important;
  border-bottom-left-radius: 20px !important;
}

.searchbox .MuiAutocomplete-popper {
  width: 47% !important;
  margin-left: 15px;
  margin-top: -2px;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App h4 {
  margin-bottom: 0.2em;
}

.subtoolbar-link {
  text-align: center;
}

.subtoolbar-link svg {
  margin-right: 0.2em;
  color: #00a394;
}
.subtoolbar-link .MuiButton-root {
  color: rgba(0, 0, 0, 0.87);
}

.App .subtoolbar-link p.MuiTypography-body1 {
  font-family: SourceSansPro-SemiBold, 'Source Sans Pro SemiBold', 'Source Sans Pro', sans-serif;
  font-weight: 650;
  font-style: normal;
  font-size: 15px;
}

.intro-req-area.intro-req-area-active {
  box-shadow: 0 -3px 3px 0px #02575025;
  max-height: 96px;
}

.intro-req-area.intro-req-area-active.xs-size {
  box-shadow: 0 -3px 3px 0px #02575025;
  max-height: 192px;
}

.intro-req-area.intro-req-area-active.intro-req-area-talent {
  max-height: 146px;
}

.intro-req-area .MuiButtonBase-root.MuiButton-containedSizeLarge {
  padding: 15px 22px;
}

.intro-req-area {
  transition: max-height 0.4s ease;
  position: fixed;
  bottom: 0;
  z-index: 100;
  background: white;
  box-shadow: 0px 0px 5px 0px #0000003d;
  max-height: 0;
}

.intro-req-area .intro-req-area-inner {
  padding-bottom: 1.2rem;
  padding-top: 1.2rem;
}

.intro-req-area .MuiTooltip-popper {
  transition:
    visibility 0s,
    opacity 0.5s linear;
  transition-delay: 0.6s;
  visibility: hidden;
  opacity: 0;
}

.intro-req-area.intro-req-area-active .MuiTooltip-popper {
  visibility: visible;
  opacity: 1;
  transition-delay: 0;
}

.poscan-shopping-cart-bottom {
  z-index: 100;
  position: fixed;
  bottom: 0;
  background: #c7c7c7;
  height: 39px;
  border-radius: 5px;
  width: 100%;
}

.poscan-shopping-cart-bottom-inner {
  width: 60%;
  margin: auto;
  font-size: 12px;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.App .counter-avatar {
  color: white;
  background-color: #025750;
}

.contact-list-area {
  margin-bottom: 130px; /* to accomodate for sticky buttons fixed at the bottom */
}

.homepage {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.homepage .image-7 {
  margin-top: 4.25em;
  margin-bottom: 3.75em;
  width: 100%;
}

.homepage .text-block-6 {
  max-width: 33.25em;
  margin-right: auto;
  margin-bottom: 1em;
  margin-left: auto;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.5em;
  line-height: 2em;
}

.homepage .paragraph-3 {
  max-width: 23.75em;
  margin-bottom: 0.625em;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  font-family: 'Source Sans Pro', sans-serif;
  color: grey;
  font-size: 1em;
}

.homepage .image-8 {
  margin-top: 2.5em;
  margin-bottom: 0.75em;
}

.homepage .text-block-8 {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1em;
  font-style: normal;
  text-decoration: none;
}

.homepage button {
  width: 320px;
  height: 50px;
  margin-bottom: 1.75em;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  border-radius: 11px;
  background-color: #025750;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 30px;
  line-height: 24px;
  font-weight: 300;
}

.homepage button:hover {
  background-color: #016f66;
}

.homepage button:active {
  background-color: #005d56;
}

.App .MuiAlert-standardInfo {
  background-color: #4696ec;
  color: white;
}
.App .MuiAlert-standardInfo .MuiAlert-icon {
  color: white;
}

.App .MuiSnackbar-anchorOriginBottomCenter {
  bottom: 55px !important;
}

.intro-req-area-active .MuiAvatar-circle {
  animation: pulse 2s forwards;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    transform: scale(0.95);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

.redbadge .MuiBadge-dot {
  background-color: #cd2a2a;
}

.dashboard-hover-action-btn {
  visibility: hidden;
}

.dashboard-row:hover .dashboard-hover-action-btn {
  visibility: visible;
}

.scroll::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  height: 1px;
}

.scroll::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

#sales-recommendations .MuiDataGrid-cell {
  overflow: scroll;
  text-overflow: unset;
}

.email-template-box {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.03);
  text-align: left;
  padding: 1rem;
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 170%;
}

.email-template-box .merge-field {
  color: #00a394;
  cursor: pointer;
}

.edit-icon {
  width: 23px;
  height: 23px;
  background-image: url('./static/edit-icon.svg');
  background-size: cover;
  display: inline-block;
  cursor: pointer;
  margin-left: 7px;
  vertical-align: middle;
}

.edit-icon:hover {
  background-image: url('./static/edit-icon-hover.svg');
}

.website-icon {
  width: 23px;
  height: 23px;
  background-image: url('./static/website-icon.svg');
  background-size: cover;
  display: inline-block;
  cursor: pointer;
  vertical-align: middle;
}

.website-icon:hover {
  background-image: url('./static/website-icon-hover.svg');
}

.linkedin-icon {
  width: 23px;
  height: 23px;
  background-image: url('./static/linkedin-icon.svg');
  background-size: cover;
  display: inline-block;
  cursor: pointer;
  vertical-align: middle;
}

.linkedin-icon:hover {
  background-image: url('./static/linkedin-icon-hover.svg');
}

.App ..MuiOutlinedInput-root {
  border-radius: 6px;
}
.App .sales-opportunities-dismiss-category .MuiFormLabel-root {
  color: rgba(0, 0, 0, 0.2);
}
